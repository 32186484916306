import React from "react"

const TorontoIcon = () => (
  <svg
    className="torontoIcon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 217 1590 595"
  >
    <path d="M1577.3,770.5v-47.8h-22.1v-38.8h-96.3v-18.9h-30.1  V684h-8.1V577.3l-40.1-9.4v93.5h-8.7v-42.8h-17.4v42.8h-8.1v13h-10.7v18.3h-14.7v-84.5h-4v-12.3h-5.3v-70.5h-46.1v53.5h-7.4v-8.1  h-3.3v-15.1h-8.1v-2.4h-26.1h-11v-28.1h-3.7v28.1h-10.7v14.7h-5.3v14h-4.6v8.1h-39.5v23.1h-13.4v-88H1119v-6.6h-31.1v6.6h-16.7v49.4  h-27.4v44.1h-40.8v22.1h-14.7v12.7h-4.6v4.6h-44.1v-4.6h-34.7v30.5h-10.1v-35.8h-39.5v-47.4h-6.6v-10.7v-8.1h-16v8.1h-16.7v10.7h-6  v47.4H795V596h-41.1v65.6h-23.1v8.7h-9.4v-4.5h-14v-34.6h-62.1v-60.6h-42.1v82.9h-36v-38.2V596h-41.5v7.8l-10.1,11.7v46.1h-8.7v34.1  h-25.4v-21.1v-13h-31.1v13H428v27.7h-14v38.2h-18h-20.7v-22.1h-8c-1.7-19.7-7.2-82.9-8.9-117.7c-1.9-38.6-5.4-125.2-6-137  c9-1.5,15.5-5.2,15.5-9.5c0-1.7-1.1-3.4-2.9-4.9h3.7v-17.9h-6.9v-10.2H349v-6.4h3.7v-8.2H349V290.2h-3.2v-39.5h-1.5V224H344v26.8  h-1.5v39.5h-3.2v116.3h-3.7v8.3h3.7v6.4h-12.9v10.2h-6.9v17.9h3.7c-1.9,1.5-2.9,3-2.9,4.9c0,4.5,6.9,8.3,16.6,9.7v202.3h-3.3v-12.7  h-4v-10.7h-15.4v10.7H309v12.7v9.4h-3.3v46.1h-9.4V711h-28.8v10.7h-8.1v18.7h-12.1V704H238v-8.3h-12.7v8.3h-8.7v-8.3h-26.1v36.2  c-9.7-5.4-20.9-9.8-33.4-12.9v-16.7h-21.3V697h-10.7v5.3h-10.7v11.4c-0.5,0-0.9,0-1.5,0c-6.6,0-13,0.4-19.2,0.9v-12.3H74.2V697H62.8  v5.3H50.1v22.5C24,735.2,5.8,751.6,2,770.5h-2.7V812h1071.8h61.5h456.7v-41.5H1577.3z" />
  </svg>
)

export default TorontoIcon
